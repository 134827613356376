/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext, useContext } from 'react';
import { productsAndBenefitsServices } from '../../services';
import { PublicContext } from '../PublicContextParent/PublicContextParent';
import { IntlContext } from '../../intl';
import { StateContext } from '../StateContextParent/StateContextParent';

export const BenefitsContext = createContext({
  hasSubscriptionBenefits: true,
});

const BenefitsStateParent = props => {
  const { children } = props;
  const [hasSubscriptionBenefits, setHasSubscriptionBenefits] = useState(false);
  const { productActive } = useContext(PublicContext);
  const { utils, state } = useContext(StateContext);
  const { idiomForApi, country } = useContext(IntlContext);

  const { isUserLogged, getBin } = utils;
  const { cards } = state;
  const isLogged = isUserLogged();
  const cardSelected = getBin(true);

  const loadbenefits = async () => {
    try {
      const { data } = await productsAndBenefitsServices.getBenefitsByProduct(
        isLogged ? cardSelected : productActive.product_id,
        idiomForApi(),
        false,
        false
      );

      const findSubscriptionBenefits = data.some(benefit =>
        Array.isArray(benefit.categories)
          ? benefit.categories.some(
              categorie => categorie === 'special_benefits'
            )
          : benefit.categories === 'special_benefits'
      );
      setHasSubscriptionBenefits(findSubscriptionBenefits);
    } catch (error) {
      setHasSubscriptionBenefits(true);
    }
  };

  useEffect(() => {
    if (
      (!isLogged && productActive && productActive.product_id) ||
      (isLogged && cardSelected)
    ) {
      loadbenefits();
    }
  }, [country, productActive, cards.selectedCardIndex]);

  const value = {
    hasSubscriptionBenefits,
  };

  return (
    <BenefitsContext.Provider value={value}>
      {children}
    </BenefitsContext.Provider>
  );
};

export default BenefitsStateParent;
