import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../componentsV2/Button/Button';
import { dataLayerContentMenuTrack } from '../../../utils/GTM_helper';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { StateContext } from '../../StateContextParent/StateContextParent';
import { IntlContext } from '../../../intl';
import { pt_BR } from '../../../intl/idioms';
import * as translations from './intl';
import styles from './KnowBenefits.module.scss';
import BR_LEFT_IMG from '../../../assets/images/specialBenefits/BR_left_AmazonPrime.png';
import BR_RIGHT_IMG from '../../../assets/images/specialBenefits/BR_right_RappiPrime.jpg';
import LAC_LEFT_IMG from '../../../assets/images/specialBenefits/LAC_left_family.png';
import LAC_RIGHT_IMG from '../../../assets/images/specialBenefits/LAC_right_Disney.jpg';
import { ENABLE_SPECIAL_BENEFITS } from '../../../utils/environments_variables';
import LoginButton from '../../../componentsV2/LoginButton/LoginButton';
import { BenefitsContext } from '../../BenefitsContextParent/BenefitsContextParent';

const KnowBenefits = () => {
  const { translate, idiom } = useContext(IntlContext);
  const { getGlobalTheme, getImageByName } = useContext(ThemeContext);
  const { hasSubscriptionBenefits } = useContext(BenefitsContext);
  const [height, setHeight] = useState();
  const { utils } = useContext(StateContext);
  const theme = getGlobalTheme();
  const intl = translate(translations);
  const originalRightImg = getImageByName('knowBenefitsRight');
  const originalLeftImg = getImageByName('knowBenefitsLeft');
  const isLogged = utils.isUserLogged();
  const history = useHistory();
  const isInSpecialBenefits = window.location.href.includes(
    'subscription-benefits'
  );
  const isBrazil = idiom === pt_BR;
  const specialBenefitsIntro = isInSpecialBenefits
    ? intl.TEXT_FULL_INTRODUCE_SPECIAL_BENEFITS
    : intl.TEXT_INTRODUCE_SPECIAL_BENEFITS;
  const leftSource = !ENABLE_SPECIAL_BENEFITS
    ? originalLeftImg
    : isInSpecialBenefits
    ? originalLeftImg
    : isBrazil
    ? BR_LEFT_IMG
    : LAC_LEFT_IMG;
  const rightSource = !ENABLE_SPECIAL_BENEFITS
    ? originalRightImg
    : isInSpecialBenefits
    ? originalRightImg
    : isBrazil
    ? BR_RIGHT_IMG
    : LAC_RIGHT_IMG;

  const redirectSpecialBenefits = () => {
    isLogged
      ? history.push('/your-subscription-benefits')
      : history.push('/subscription-benefits');
  };

  const getCorrectHeight = () => {
    if (window.innerWidth > 992) {
      setHeight((window.innerWidth / 2) * 0.6);
    } else {
      setHeight(window.innerWidth * 0.6);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', getCorrectHeight);

    return () => {
      window.removeEventListener('resize', getCorrectHeight);
    };
  }, []);

  useEffect(() => {
    getCorrectHeight();
  }, []);

  if (!hasSubscriptionBenefits) {
    return <></>;
  }

  return (
    <section className={styles.knowBenefits} data-testid="knowBenefitsView">
      <div className={styles.blackCover}></div>
      <div className={styles.containerKnowBenefits}>
        <div
          className={`${styles.image}`}
          style={{
            backgroundImage: `url(${leftSource})`,
            height,
          }}
          alt=""
        />
        <div
          className={`${styles.image}`}
          style={{
            backgroundImage: `url(${rightSource})`,
            height,
          }}
          alt=""
        />

        <div className={`${styles.knowBenefitsInfo}`}>
          <h2 className={styles[theme]}>{specialBenefitsIntro}</h2>
          {isInSpecialBenefits || !ENABLE_SPECIAL_BENEFITS ? (
            <>
              <LoginButton
                surface="Inverse"
                track={() => dataLayerContentMenuTrack('CTA Log in', null)}
              />
            </>
          ) : (
            <Button
              surface="Inverse"
              label={intl.BUTTON_TEXT}
              onClick={redirectSpecialBenefits}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default KnowBenefits;
