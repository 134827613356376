/* eslint-disable indent */
import * as Yup from 'yup';
import moment from 'moment';

export const tripInformationSchema = intl =>
  Yup.object().shape({
    travel_information: Yup.object().shape({
      is_international_trip: Yup.string().required(intl.TEXT_REQUIRED_FIELD),
      is_one_way_travel: Yup.boolean().required(intl.TEXT_REQUIRED_FIELD),
      from: Yup.string()
        .required(intl.TEXT_REQUIRED_FIELD)
        .typeError(intl.TEXT_REQUIRED_FIELD)
        .test(
          'test-data-after-today',
          intl.VALIDATE_DATE_VALID,
          function (value) {
            return moment(
              moment(value).format('YYYY-MM-DD 00:00:00')
            ).isSameOrAfter(moment(new Date()).format('YYYY-MM-DD 00:00:00'));
          }
        ),
      to: Yup.mixed()
        .required(intl.TEXT_REQUIRED_FIELD)
        .when(
          ['is_one_way_travel'],
          {
            is: is_one_way_travel => {
              return !is_one_way_travel;
            },
            then: Yup.mixed()
              .test(
                'test-60-days',
                intl.VALIDATE_MAX_60_DAYS,
                function (value) {
                  return this.parent.from
                    ? moment(value).isSameOrBefore(
                        moment(this.parent.from).add(60, 'days')
                      )
                    : true;
                }
              )
              .test(
                'test-data-valid',
                intl.VALIDATE_DATE_VALID,
                function (value) {
                  return moment(value).isSameOrAfter(this.parent.from);
                }
              ),
          },
          ['is_one_way_travel']
        ),
      country_origin: Yup.string().when(['is_international_trip'], {
        is: is_international_trip => {
          return is_international_trip === 'true';
        },
        then: Yup.string().required(intl.TEXT_REQUIRED_FIELD),
      }),
      country_destination: Yup.string()
        .required(intl.TEXT_REQUIRED_FIELD)
        .when(['is_international_trip'], {
          is: is_international_trip => {
            return is_international_trip === 'true';
          },
          then: Yup.string().test(
            'test-originDestination-valid',
            intl.VALIDATE_ORIGIN_DESTINATION,
            function (value) {
              return this.parent.country_origin !== value;
            }
          ),
        }),
      city_origin: Yup.string().when(['is_international_trip'], {
        is: is_international_trip => {
          return is_international_trip === 'false';
        },
        then: Yup.string().required(intl.TEXT_REQUIRED_FIELD),
      }),
      city_destination: Yup.string().when(['is_international_trip'], {
        is: is_international_trip => {
          return is_international_trip === 'false';
        },
        then: Yup.string()
          .test(
            'test-originDestination-valid',
            intl.VALIDATE_ORIGIN_DESTINATION,
            function (value) {
              return this.parent.city_origin !== value;
            }
          )
          .required(intl.TEXT_REQUIRED_FIELD),
      }),
    }),
  });

export const emptyValidationSchema = intl =>
  Yup.object().shape({
    travel_information: Yup.object().shape({
      is_international_trip: Yup.string().required(intl.TEXT_REQUIRED_FIELD),
      is_one_way_travel: Yup.boolean(),
      from: Yup.string().test(
        'test-data-after-today',
        intl.VALIDATE_DATE_VALID,
        function (value) {
          if (value) {
            return moment(
              moment(value).format('YYYY-MM-DD 00:00:00')
            ).isSameOrAfter(moment(new Date()).format('YYYY-MM-DD 00:00:00'));
          }
          return true;
        }
      ),
      to: Yup.mixed().when(
        ['is_one_way_travel'],
        {
          is: is_one_way_travel => {
            return !is_one_way_travel;
          },
          then: Yup.mixed()
            .test('test-60-days', intl.VALIDATE_MAX_60_DAYS, function (value) {
              return this.parent.from
                ? moment(value).isSameOrBefore(
                    moment(this.parent.from).add(60, 'days')
                  )
                : true;
            })
            .test(
              'test-data-valid',
              intl.VALIDATE_DATE_VALID,
              function (value) {
                if (this.parent.from) {
                  return moment(value).isSameOrAfter(this.parent.from);
                }
                return true;
              }
            ),
        },
        ['is_one_way_travel']
      ),
      country_origin: Yup.string(),
      country_destination: Yup.string().test(
        'test-originDestination-valid',
        intl.VALIDATE_ORIGIN_DESTINATION,
        function (value) {
          if (this.parent.country_origin) {
            return this.parent.country_origin !== value;
          }
          return true;
        }
      ),
    }),
  });
