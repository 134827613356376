/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import * as constants from './consts';

let is_accidental_death = false;
let have_people_involved = false;

export const injuredJournaySchema = intl => {
  let object = {};

  object.is_accidental_death = isAccidentalDeathSchema(intl);
  object.affected_person = affectedPersonSchema(intl);
  object.policy = policySchema(intl);
  object.incident = incidentSchema(intl);

  object.people_involved = peopleInvolvedShema(intl);

  object.have_people_involved = have_people_involvedSchema(intl);

  return Yup.object().shape(object);
};

const isAccidentalDeathSchema = intl => {
  return Yup.lazy(value => {
    let isAccidentalDeath = Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .nullable();
    if (value) {
      is_accidental_death = value;
    }
    return Yup.object()
      .shape({
        isAccidentalDeath,
      })
      .nullable();
  });
};

const have_people_involvedSchema = intl => {
  return Yup.lazy(value => {
    if (value) {
      have_people_involved = value;
    }
    return Yup.object().shape({}).nullable();
  });
};

const affectedPersonSchema = intl => {
  return Yup.lazy(value => {
    let affectedPerson = {};

    affectedPerson.relationship = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );

    affectedPerson.comment = Yup.string().when(['relationship'], {
      is: relationship => {
        return relationship === constants.OTHER_VALUE;
      },
      then: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .max(255, intl.TEXT_FIELD_VALIDATION_255),
    });
    affectedPerson.person = Yup.object().shape({
      first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      nationalities: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
    });

    if (value.relationship !== constants.CARD_HOLDER_VALUE) {
      affectedPerson.address = Yup.object().shape({
        invalidCep: Yup.boolean().test(
          'invalid ajax cep',
          'invalid ajax cep',
          function (value) {
            if (this.parent.country !== 'BR') return true;
            if (value === true) return false;
            return true;
          }
        ),
        street_address: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .max(255, intl.TEXT_FIELD_VALIDATION_255),
        postal_code: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .test('test-cep-br-valid', intl.VALIDATE_CEP, function (value) {
            if (value) {
              const cep = value.replace('-', '');
              if (this.parent.country !== 'BR') {
                return cep.length > 0 && cep.length <= 8;
              }
              return cep.length === 8;
            }
            return true;
          })
          .typeError(intl.TEXT_FIELD_REQUIRED),
        locality: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        country: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        subdivision: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        state: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      });
    }

    if (is_accidental_death !== 'true') {
      if (value.relationship !== constants.CARD_HOLDER_VALUE) {
        affectedPerson.email = Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .email(intl.VALIDATE_EMAIL_VALID);

        affectedPerson.phones = Yup.object().shape({
          phone_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          international_prefix: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        });
        affectedPerson.professional_situation = Yup.object().shape({
          occupation: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        });

        if (value.person_registration.registration_type === 'CPF') {
          affectedPerson.person_registration = Yup.object().shape({
            value: Yup.string()
              .validateCPF(intl.VALIDATE_CPF)
              .required(intl.TEXT_FIELD_REQUIRED),
            registration_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          });
        } else {
          affectedPerson.person_registration = Yup.object().shape({
            value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            registration_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          });
        }
      }

      if (value.family_situation.marital_status === 'MARRIED') {
        affectedPerson.family_situation = Yup.object().shape({
          lived_in_couple_since: Yup.date()
            .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_DATE_VALID)
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        });
      }
    }

    return Yup.object().shape({
      ...affectedPerson,
    });
  });
};

const policySchema = intl => {
  return Yup.lazy(value => {
    let policy = {};

    if (is_accidental_death !== 'true') {
      policy.credit_card = Yup.object().shape({
        additional_card_intormation: Yup.object().shape({
          activation_date: Yup.date()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED)
            .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_DATE_VALID),
          expiration_date: Yup.date()
            .validateDateIsSameOrAfterParentDate(
              intl.VALIDATE_DATE_VALID,
              'activation_date'
            )
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        }),
      });
    }
    return Yup.object().shape({
      ...policy,
    });
  });
};

const incidentSchema = intl => {
  return Yup.lazy(value => {
    let incident = {};

    incident.occured_at = Yup.date()
      .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_DATE_VALID)
      .required(intl.TEXT_FIELD_REQUIRED)
      .typeError(intl.TEXT_FIELD_REQUIRED);

    incident.description = Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255);

    if (is_accidental_death === 'true') {
      incident.location = Yup.object().shape({
        country: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        state: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        locality: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        street_address: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .max(255, intl.TEXT_FIELD_VALIDATION_255),
      });
    }
    return Yup.object().shape({
      ...incident,
    });
  });
};

const peopleInvolvedShema = intl => {
  return Yup.lazy(value => {
    if (is_accidental_death !== 'true' && have_people_involved === 'true') {
      const peopleInvolved = Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          person: Yup.object().shape({
            first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
          address: Yup.object().shape({
            country: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            state: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            locality: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            postal_code: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            street_address: Yup.string()
              .required(intl.TEXT_FIELD_REQUIRED)
              .max(255, intl.TEXT_FIELD_VALIDATION_255),
            subdivision: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
          phone: Yup.object().shape({
            phone_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            international_prefix: Yup.string().required(
              intl.TEXT_FIELD_REQUIRED
            ),
            number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
        })
      );

      return peopleInvolved;
    } else {
      return Yup.object().shape({}).nullable();
    }
  });
};
