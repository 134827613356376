import React, { Component, createRef } from 'react';
import { generalInformationFormInitialValues } from '../../../../Claims/Generate/ClaimFillForm/Form/GeneralInformation/initialValues';
import AcordeonSession from '../../../../../components/AcordeonSession/AcordeonSession';
import GeneralInformation from '../../../../Claims/Generate/ClaimFillForm/Form/GeneralInformation/GeneralInformation';
import TripInformation from './TripInformation/TripInformation';
import { tripInitialValues } from './TripInformation/initialValues';
import LegalAdviceAndConsent from '../LegalAdviceAndConsent/LegalAdviceAndConsent';

export default class AutoRental extends Component {
  constructor(props) {
    super(props);
    this.keys = ['policy', 'tripInformation', 'legal'];
    this.submitRefs = {
      policy: createRef(),
      tripInformation: createRef(),
      legal: createRef(),
    };
    this.accordeonRefs = {
      policy: createRef(),
      tripInformation: createRef(),
      legal: createRef(),
    };
    this.initialValues = {
      policy: generalInformationFormInitialValues(
        props.loadedData.cardHolder,
        props.selectedCard.issuer_name,
        props.idiom,
        true
      ),
      tripInformation: tripInitialValues(this.props.idiom),
    };

    this.state = {
      listAccordeon: {
        policy: true,
        tripInformation: false,
        legal: false,
      },
    };
  }

  componentDidMount() {
    this.props.setInitialValuesCompare(this.initialValues);
  }

  setStatusAccordeon = name => {
    const newList = {
      policy: false,
      tripInformation: false,
      legal: false,
    };
    newList[name] = !this.state.listAccordeon[name];
    this.setState({ listAccordeon: newList });
  };

  render() {
    return (
      <>
        <AcordeonSession
          id="policy"
          number={1}
          title={this.props.intl.TITLE_TRAVELLER_INFORMATION}
          openButtonRef={this.accordeonRefs.policy}
          status={this.state.listAccordeon['policy']}
          setStatus={() => this.setStatusAccordeon('policy')}
          next={async () => {
            const responseOK = await this.props.openTargetAcordeon(
              'policy',
              'tripInformation',
              true
            );
            if (responseOK) {
              await this.props.generalInformationCheck();
            }
          }}
          back={() =>
            this.props.history.push('/certificates/previous-certificates')
          }
        >
          <GeneralInformation
            policySubmitRef={this.submitRefs.policy}
            updateParent={this.props.updateForm.bind(this, 'policy')}
            initialValues={this.initialValues.policy}
            isCertificate
            productName={this.props.selectedCard.name}
            prefixOptions={this.props.prefixOptions}
          />
        </AcordeonSession>
        <AcordeonSession
          id="tripInformation"
          number={2}
          title={this.props.intl.TITLE_TRIP_INFORMATION}
          openButtonRef={this.accordeonRefs.tripInformation}
          status={this.state.listAccordeon['tripInformation']}
          setStatus={() => this.setStatusAccordeon('tripInformation')}
          next={() =>
            this.props.openTargetAcordeon('tripInformation', 'legal', true)
          }
          back={() =>
            this.props.openTargetAcordeon('tripInformation', 'policy', false)
          }
        >
          <TripInformation
            tripSubmitRef={this.submitRefs.tripInformation}
            updateParent={this.props.updateForm.bind(this, 'tripInformation')}
            initialValues={this.initialValues.tripInformation}
          />
        </AcordeonSession>
        <AcordeonSession
          id="legal"
          title={this.props.intl.TITLE_BENEFIT_TERMS}
          openButtonRef={this.accordeonRefs.legal}
          accordeon="legal"
          status={this.state.listAccordeon['legal']}
          setStatus={() => this.setStatusAccordeon('legal')}
          txtBtnNext={this.props.intl.TEXT_SUBMIT_CERTIFICATE}
          next={this.props.submit}
          back={() =>
            this.props.openTargetAcordeon('legal', 'tripInformation', false)
          }
        >
          <LegalAdviceAndConsent
            updateParent={this.props.updateForm.bind(null, 'legal')}
            legalSubmitRef={this.submitRefs.legal}
          />
        </AcordeonSession>
      </>
    );
  }
}
