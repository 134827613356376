import moment from 'moment';
import * as Yup from 'yup';
import { validateCPF } from '../../../../../utils/stringUtils';

export const validationSchema = intl =>
  Yup.object().shape({
    MPH: Yup.object().shape({
      Name: Yup.string()
        .required(intl.VALIDATION_MESSAGE_REQUIRED)
        .matches(/([a-zA-Z])/, intl.VALIDATION_MESSAGE_NAME),
      LastName: Yup.string()
        .required(intl.VALIDATION_MESSAGE_REQUIRED)
        .matches(/([a-zA-Z])/, intl.VALIDATION_MESSAGE_NAME),
      DateOfBirth: Yup.mixed()
        .test('match', intl.VALIDATION_MESSAGE_DATE, function (birth_date) {
          let yesterday = moment(new Date())
            .subtract(1, 'days')
            .format('YYYY-MM-DD HH:ss');
          return moment(birth_date).isSameOrBefore(yesterday);
        })
        .typeError(intl.VALIDATION_MESSAGE_REQUIRED),
      Email: Yup.string()
        .email(intl.VALIDATION_MESSAGE_EMAIL)
        .required(intl.VALIDATION_MESSAGE_REQUIRED),
      EmailConfirmation: Yup.string()
        .required(intl.VALIDATION_MESSAGE_REQUIRED)
        .test(
          'email-match',
          intl.VALIDATION_MESSAGE_SAME_EMAIL,
          function (value) {
            return this.parent.Email === value;
          }
        )
        .email(intl.VALIDATION_MESSAGE_SAME_EMAIL),
      person_registrations: Yup.object().shape({
        value: Yup.string()
          .required(intl.VALIDATION_MESSAGE_REQUIRED)
          .when(
            ['registration_type'],
            {
              is: registration_type => {
                return registration_type === 'CPF';
              },
              then: Yup.string()
                .required(intl.VALIDATION_MESSAGE_REQUIRED)
                .test('test-data-valid', intl.VALIDATE_CPF, function (value) {
                  return validateCPF(value);
                }),
            },
            ['registration_type']
          ),
      }),
      phone: Yup.object().shape({
        phone_type: Yup.string()
          .required(intl.VALIDATION_MESSAGE_REQUIRED)
          .typeError(intl.VALIDATION_MESSAGE_REQUIRED),
        number: Yup.string().required(intl.VALIDATION_MESSAGE_REQUIRED),
        international_prefix: Yup.string().required(
          intl.VALIDATION_MESSAGE_REQUIRED
        ),
      }),
      address: Yup.object().shape({
        postal_code: Yup.string()
          .required(intl.VALIDATION_MESSAGE_REQUIRED)
          .test(
            'test-cep-br-valid',
            intl.VALIDATION_MESSAGE_CEP,
            function (value) {
              if (value) {
                const cep = value.replace('-', '');
                if (this.parent.country !== 'BR') {
                  return cep.length > 0 && cep.length <= 8;
                }
                return cep.length === 8;
              }
              return true;
            }
          )
          .typeError(intl.VALIDATION_MESSAGE_REQUIRED),
        state: Yup.string().required(intl.VALIDATION_MESSAGE_REQUIRED),
        street_address: Yup.string()
          .required(intl.VALIDATION_MESSAGE_REQUIRED)
          .max(255, intl.TEXT_FIELD_VALIDATION_255),
        locality: Yup.string().required(intl.VALIDATION_MESSAGE_REQUIRED),
        subdivision: Yup.string(),
        country: Yup.string().required(intl.VALIDATION_MESSAGE_REQUIRED),
      }),
    }),
  });
