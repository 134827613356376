/* eslint-disable no-unused-vars */
const moduleCitiB = sumSeq => {
  var mod = sumSeq % 11;
  if (mod > 1) {
    return 11 - mod;
  } else {
    return '0';
  }
};

const agencyNumberIsValid = agencyNumber => {
  return /^[0-9]{1,5}$/.test(agencyNumber) && parseInt(agencyNumber) > 0;
};

const accountNumberLength = () => {
  return 12;
};

const agencyNumberLength = () => {
  return 4;
};

/**
 * Receive account and agency with '-'
 * Agency format: xxxx-x
 * Account format: xxxxxxxx-x
 */
export default () => ({
  account: (code, agency = null) => {
    if (!code || code.length !== accountNumberLength()) return false;
    let arrayCode = code.split('-');
    let numbers = arrayCode[0].split('');
    let dv = arrayCode[1];

    let sum = 0;
    let index = 11;
    numbers.forEach(n => {
      sum += parseInt(n) * index;
      index--;
    });
    const calculatedNumber = moduleCitiB(sum);

    return (
      calculatedNumber.toString().toUpperCase() === dv.toString().toUpperCase()
    );
  },
  agency: code => {
    if (!code || code.length !== agencyNumberLength()) return false;
    return agencyNumberIsValid(code);
  },
  accountNumberLength: code => {
    if (!code) return false;
    return code.length === accountNumberLength();
  },
  agencyNumberLength: code => {
    if (!code) return false;
    return code.length === agencyNumberLength();
  },
  getAgencyNumberLength: () => {
    return agencyNumberLength();
  },
  getAccountNumberLength: () => {
    return accountNumberLength();
  },
  getMaskAgencyName: () => {
    return 'maskCitibankBranchCode';
  },
  getMaskAccountName: () => {
    return 'maskCitibankAccountNumber';
  },
  isGenericValidation: () => {
    return false;
  },
  getFormatAccount: () => {
    return '00000000-0';
  },
});
