const moduleItau = sumSeq => {
  var module = sumSeq % 10;
  if (module === 0) {
    return '0';
  } else {
    return (10 - module).toString();
  }
};

const multiplyAccordingParity = (number, index) => {
  return number * (index % 2 === 0 ? 2 : 1);
};

const adjustAccordingLength = sequence => {
  if (sequence > 9) {
    var numbers = sequence.toString().split('');
    sequence = 0;
    for (var i = 0; i < numbers.length; i++) {
      sequence += parseInt(numbers[i]);
    }
  }
  return sequence;
};

const accountNumberLength = () => {
  return 7;
};

const agencyNumberLength = () => {
  return 4;
};

/**
 * Receive account and agency with '-'
 * Agency format: xxxx
 * Account format: xxxxx-x
 */
export default () => ({
  account: (code, agency) => {
    try {
      if (!code || code.length !== accountNumberLength()) return false;
      if (agency !== null && agency.length !== agencyNumberLength())
        return false;

      let arrayCode = code.split('-');
      let numbers = (agency + arrayCode[0]).split('');
      let dv = arrayCode[1];

      let sumSeq = 0;
      let sequence = 0;
      for (var i = 0; i < numbers.length; i++) {
        let number = parseInt(numbers[i]);
        sequence = multiplyAccordingParity(number, i);
        sequence = adjustAccordingLength(sequence);
        sumSeq += sequence;
      }
      const calculatedNumber = moduleItau(sumSeq);

      return (
        calculatedNumber.toString().toUpperCase() ===
        dv.toString().toUpperCase()
      );
    } catch (err) {
      return false;
    }
  },

  agency: code => {
    try {
      if (!code || code.length !== agencyNumberLength()) return false;
      return true;
    } catch (err) {
      return false;
    }
  },
  accountNumberLength: code => {
    if (!code) return false;
    return code.length === accountNumberLength();
  },
  agencyNumberLength: code => {
    if (!code) return false;
    return code.length === agencyNumberLength();
  },
  getAgencyNumberLength: () => {
    return agencyNumberLength();
  },
  getAccountNumberLength: () => {
    return accountNumberLength();
  },
  getMaskAgencyName: () => {
    return 'maskItauBranchCode';
  },
  getMaskAccountName: () => {
    return 'maskItauAccountNumber';
  },
  isGenericValidation: () => {
    return false;
  },
  getFormatAccount: () => {
    return '00000-0';
  },
});
