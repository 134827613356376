import * as Yup from 'yup';
import { CARD } from '../../../../../utils/consts';
import moment from 'moment';

export const validationDataSchema = (intl, idiom) => {
  let object = {};

  object.payment = paymentSchema(intl, idiom);

  return Yup.object().shape(object);
};

const paymentSchema = intl => {
  let payment = {};

  payment.nombre = Yup.string().when(['tarjeta'], {
    is: tarjeta => {
      return tarjeta === CARD;
    },
    then: Yup.string().required(intl.REQUIRED_FIELD),
  });
  // payment.postalCode = Yup.string().when(['tarjeta'], {
  //   is: (tarjeta) => {
  //     return tarjeta === CARD;
  //   },
  //   then: Yup.string()
  // });
  // payment.number = Yup.string().when(['tarjeta'], {
  //   is: (tarjeta) => {
  //     return tarjeta === CARD;
  //   },
  //   then: Yup.string().required(intl.REQUIRED_FIELD)
  // });

  payment.date = Yup.string().when(['tarjeta'], {
    is: tarjeta => {
      return tarjeta === CARD;
    },
    then: Yup.string()
      .test('validate expiration date', intl.INVALID_DATE, function (value) {
        if (!value) {
          return true;
        }
        if (value.length < 4) {
          return false;
        }

        const today = moment(new Date());
        let valueDate = new Date(
          `${value[0]}${value[1]}/01/${value[3]}${value[4]}`
        );
        return moment(today).isBefore(valueDate);
      })
      .required(intl.REQUIRED_FIELD),
  });
  // payment.cvc = Yup.string().when(['tarjeta'], {
  //   is: (tarjeta) => {
  //     return tarjeta === CARD;
  //   },
  //   then: Yup.string().required(intl.REQUIRED_FIELD)
  // });

  return Yup.object().shape({
    ...payment,
  });
};
