const agencyNumberIsValid = agencyNumber => {
  return /^[0-9]{1,6}$/.test(agencyNumber) && parseInt(agencyNumber) > 0;
};

const accountNumberIsValid = accountNumber => {
  return /^[0-9]{1,12}$/.test(accountNumber) && parseInt(accountNumber) > 0;
};

const accountNumberLength = () => {
  return 12;
};

const agencyNumberLength = () => {
  return 4;
};

/**
 * Receive account and agency with '-'
 * Agency format: xxxx
 * Account format: xxxxxxxxxxxx
 */
export default () => ({
  account: code => {
    if (!code) return false;
    if (code && code.length !== accountNumberLength()) return false;
    return accountNumberIsValid(code);
  },
  agency: code => {
    if (!code) return false;
    if (code.length < agencyNumberLength()) return false;
    return agencyNumberIsValid(code);
  },
  accountNumberLength: code => {
    if (!code) return false;
    return code.length === accountNumberLength();
  },
  agencyNumberLength: code => {
    if (!code) return false;
    return code.length >= agencyNumberLength();
  },
  getAgencyNumberLength: () => {
    return agencyNumberLength();
  },
  getAccountNumberLength: () => {
    return accountNumberLength();
  },
  getMaskAgencyName: () => {
    return 'maskGenericBranchCode';
  },
  getMaskAccountName: () => {
    return 'maskGenericAccountNumber';
  },
  isGenericValidation: () => {
    return true;
  },
  getFormatAccount: () => {
    return '000000000000';
  },
});
