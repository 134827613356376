import { Fragment, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from '../../intl';
import * as translations from './intl';
import styles from './Footer.module.scss';
import { filterByPosition } from './data';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import {
  ENABLE_OFFERS,
  ENABLE_SPECIAL_BENEFITS,
} from '../../utils/environments_variables';
import sanitize from '../../utils/sanitize';
import Logo from '../Logo/Logo';
import { joinClassNames } from '../../utils/classNames';
import Link from '../../componentsV2/Link/Link';
import { BenefitsContext } from '../BenefitsContextParent/BenefitsContextParent';

const Footer = () => {
  const { translate, idiom, country } = useIntl();
  const { hasSubscriptionBenefits } = useContext(BenefitsContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  return (
    <footer className={styles.footer}>
      <div className={`${styles.separator} ${styles[theme]}`}></div>
      <div className="row">
        <div
          className={`col-md-2 col-sm-12 order-2 order-md-1 order-lg-1 order-xl-1 order-sm-2 ${styles.logoFooter}`}
        >
          <RouterLink
            to="/"
            className={joinClassNames(styles.logo, styles[theme])}
          >
            <Logo color="inherit" size="MEDIUM" />
          </RouterLink>
        </div>
        <div className="col-md-8 col-sm-12 order-md-2 order-sm-1">
          <div className="row">
            <div className={`col-md-4 col-sm-12 ${styles.firstSm}`}>
              <ul className={styles.navLeft}>
                {filterByPosition(intl, 'left', idiom).map(data => {
                  if (data.href === '/offers' && !ENABLE_OFFERS) {
                    return <Fragment key={data.id}></Fragment>;
                  }
                  if (
                    (data.href === '/subscription-benefits' &&
                      !ENABLE_SPECIAL_BENEFITS) ||
                    (data.href === '/subscription-benefits' &&
                      !hasSubscriptionBenefits)
                  ) {
                    return <Fragment key={data.id}></Fragment>;
                  }
                  return (
                    <li key={`footer-link-left-${data.id}`}>
                      <Link
                        label={sanitize(data.text)}
                        internal
                        href={sanitize(data.href)}
                        icon={false}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={`col-md-8 col-sm-12 ${styles.firstSm}`}>
              <ul className={styles.navRight}>
                {filterByPosition(intl, 'right', idiom).map((data, index) => {
                  return (
                    <li key={`footer-link-right-${index}`}>
                      <Link
                        label={sanitize(data.text)}
                        href={sanitize(data.href)}
                        target={data.target}
                        icon={data.target?.toLowerCase() === '_blank'}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className={`col-md-2 col-sm-12 order-3 ${styles.visa}`}>
          <Link
            label={intl.TEXT_VISA_LINK}
            href={sanitize(intl.VISA_LINK(country))}
          />
        </div>
        <div className="col-12 order-md-4 order-4">
          <span className={`${styles.copyright} ${styles[theme]}`}>
            {' '}
            {intl.TEXT_COPYRIGHT(new Date().getFullYear())}{' '}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
