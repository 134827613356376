/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import moment from 'moment';
import * as constants from './consts';

let kind = 'ASSAULT';
export const atmSchema = (intl, isBrazil) => {
  let object = {};

  object.incident = incidentSchema(intl, isBrazil);
  object.affected_person = affectedPersonSchema(intl);
  object.other_insurance_covering = otherInsuranceSchema(intl);

  return Yup.object().shape(object);
};

const incidentSchema = (intl, isBrazil) => {
  return Yup.lazy(value => {
    let incident = {};
    incident.kind = Yup.string().required(intl.TEXT_FIELD_REQUIRED);
    kind = value.kind;
    incident.description = Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255);

    incident.date = Yup.string()
      .required(intl.VALIDATE_DATE_VALID)
      .typeError(intl.VALIDATE_DATE_VALID)
      .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (date) {
        const _date = moment(date).format('YYYY-MM-DD');
        const _dateCurrent = moment(new Date()).format('YYYY-MM-DD');
        return moment(_date).isSameOrBefore(_dateCurrent);
      });

    incident.hour = Yup.string()
      .required(intl.VALIDATE_HOUR_VALID)
      .typeError(intl.VALIDATE_HOUR_VALID)
      .length(5, intl.VALIDATE_HOUR_VALID)
      .test('test-hour-valid', intl.VALIDATE_HOUR_VALID, function (hour) {
        return moment(hour, 'hh:mm').isValid();
      });

    incident.location = Yup.object().shape({
      invalidCep: Yup.boolean().test(
        'invalid ajax cep',
        'invalid ajax cep',
        function (value) {
          if (this.parent.country !== 'BR') return true;
          if (value === true) return false;
          return true;
        }
      ),
      country: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      locality: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      postal_code: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .test('test-cep-br-valid', intl.VALIDATE_CEP, function (value) {
          if (value) {
            const cep = value.replace('-', '');
            if (this.parent.country !== 'BR') {
              return cep.length > 0 && cep.length <= 8;
            }
            return cep.length === 8;
          }
          return true;
        })
        .typeError(intl.TEXT_FIELD_REQUIRED),
      state: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      street_address: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .max(255, intl.TEXT_FIELD_VALIDATION_255),
      subdivision: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
    });

    if (kind === constants.VALUE_ASSAULT) {
      incident.withdrawn_amount = Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        currency: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
      });

      incident.stolen_amount = Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        currency: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
      });
    }

    return Yup.object().shape({
      ...incident,
    });
  });
};

const affectedPersonSchema = intl => {
  return Yup.lazy(value => {
    let affectedPerson = {};
    affectedPerson.relationship = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );
    affectedPerson.comment = Yup.string().when(['relationship'], {
      is: relationship => {
        return relationship === constants.OTHER_VALUE;
      },
      then: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .max(255, intl.TEXT_FIELD_VALIDATION_255),
    });

    if (kind === 'DEATH') {
      affectedPerson.person = Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        gender: Yup.string().when(['relationship'], {
          is: relationship => {
            return value.relationship !== constants.CARD_HOLDER_VALUE;
          },
          then: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        }),
        birth_date: Yup.string().when(['relationship'], {
          is: relationship => {
            return value.relationship !== constants.CARD_HOLDER_VALUE;
          },
          then: Yup.string()
            .required(intl.VALIDATE_DATE_VALID)
            .typeError(intl.VALIDATE_DATE_VALID)
            .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (date) {
              const _date = moment(date).format('YYYY-MM-DD');
              const _dateCurrent = moment(new Date()).format('YYYY-MM-DD');
              return moment(_date).isSameOrBefore(_dateCurrent);
            }),
        }),
      });

      affectedPerson.deceased_at = Yup.string()
        .required(intl.VALIDATE_DATE_VALID)
        .typeError(intl.VALIDATE_DATE_VALID)
        .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (date) {
          const _date = moment(date).format('YYYY-MM-DD');
          const _dateCurrent = moment(new Date()).format('YYYY-MM-DD');
          return moment(_date).isSameOrBefore(_dateCurrent);
        });
    } else {
      affectedPerson.person = Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      });
    }

    return Yup.object().shape({
      ...affectedPerson,
    });
  });
};

const otherInsuranceSchema = intl => {
  return Yup.lazy(value => {
    let other_insurance_covering = {};

    if (kind === constants.VALUE_ASSAULT) {
      if (value.checked === 'true') {
        other_insurance_covering.amount = Yup.object().shape({
          value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          currency: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        });
      }
    }

    return Yup.object().shape({
      ...other_insurance_covering,
    });
  });
};
