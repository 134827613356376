import * as Yup from 'yup';
import moment from 'moment';
import * as constants from './consts';

let dateFrom;
let dateTo;

export const validationMissedSchema = intl =>
  Yup.object().shape({
    affected_person: Yup.object().shape({
      relationshipCardholder: Yup.bool().oneOf(
        [true],
        intl.TEXT_FIELD_REQUIRED
      ),
      person: Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),

    incident: Yup.object().shape({
      date: Yup.date()
        .required(intl.VALIDATE_DATE_VALID)
        .typeError(intl.VALIDATE_DATE_VALID)
        .test(
          'valid-date',
          intl.VALIDATE_DATE_BETWEEN_FROM_AND_TO,
          function (date) {
            return (
              moment(date).isSameOrAfter(dateFrom) &&
              moment(date).isSameOrBefore(dateTo)
            );
          }
        ),
      time: Yup.string()
        .required(intl.VALIDATE_HOUR_VALID)
        .typeError(intl.VALIDATE_HOUR_VALID)
        .length(5, intl.VALIDATE_HOUR_VALID)
        .test('test-hour-valid', intl.VALIDATE_HOUR_VALID, function (hour) {
          return moment(hour, 'hh:mm').isValid();
        }),
      description: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED)
        .max(255, intl.TEXT_FIELD_VALIDATION_255),
    }),

    travel_information: Yup.lazy(value => {
      dateFrom = moment(value.from);
      dateTo = moment(value.to);
      return Yup.object().shape({
        agency_information: Yup.object().shape({
          name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        }),
        form_of_payment: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        from: Yup.date()
          .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_DATE_VALID)
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        to: Yup.date()
          .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_DATE_VALID)
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        programmed_flights: Yup.object().shape({
          departure_date: Yup.date()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED)
            .test(
              'valid-date',
              intl.VALIDATE_DATE_BETWEEN_FROM_AND_TO,
              function (date) {
                return (
                  moment(date).isSameOrAfter(dateFrom) &&
                  moment(date).isSameOrBefore(dateTo)
                );
              }
            ),

          departure_time: Yup.string()
            .required(intl.VALIDATE_HOUR_VALID)
            .typeError(intl.VALIDATE_HOUR_VALID)
            .length(5, intl.VALIDATE_HOUR_VALID)
            .test('test-hour-valid', intl.VALIDATE_HOUR_VALID, function (hour) {
              return moment(hour, 'hh:mm').isValid();
            }),

          arrival_date: Yup.date()
            .validateDateIsSameOrAfterParentDate(
              intl.VALIDATE_DATE_VALID,
              'departure_date'
            )
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),

          arrival_time: Yup.string()
            .required(intl.VALIDATE_HOUR_VALID)
            .typeError(intl.VALIDATE_HOUR_VALID)
            .length(5, intl.VALIDATE_HOUR_VALID)
            .test('test-hour-valid', intl.VALIDATE_HOUR_VALID, function (hour) {
              return moment(hour, 'hh:mm').isValid();
            })
            .test(
              'test-date-time-valid',
              intl.VALIDATE_HOUR_BIGGER_DEPARTURE,
              function (hour) {
                if (
                  moment(this.parent.departure_date).isValid() &&
                  moment(this.parent.arrival_date).isValid() &&
                  hour
                ) {
                  const splitArrivalHour = hour.split(':');
                  const splitDepartureHour = moment(
                    this.parent.departure_time,
                    'hh:mm'
                  ).isValid()
                    ? this.parent.departure_time.split(':')
                    : ['00', '00'];
                  const arrivalDate = moment(this.parent.arrival_date)
                    .add(
                      Number(splitArrivalHour[0] * 60) +
                        Number(splitArrivalHour[1]),
                      'minutes'
                    )
                    .format('YYYY-MM-DD HH:mm:ss');
                  const departureDate = moment(this.parent.departure_date)
                    .add(
                      Number(splitDepartureHour[0] * 60) +
                        Number(splitDepartureHour[1]),
                      'minutes'
                    )
                    .format('YYYY-MM-DD HH:mm:ss');
                  return moment(arrivalDate).isAfter(departureDate);
                }

                return true;
              }
            ),
        }),
        programmed_flights2: Yup.object().shape({
          departure_programmed_date: Yup.date()
            .required(intl.VALIDATE_DATE_VALID)
            .typeError(intl.VALIDATE_DATE_VALID)
            .test(
              'valid-date',
              intl.VALIDATE_DATE_BETWEEN_FROM_AND_TO,
              function (date) {
                return (
                  moment(date).isSameOrAfter(dateFrom) &&
                  moment(date).isSameOrBefore(dateTo)
                );
              }
            ),

          departure_programmed_time: Yup.string()
            .required(intl.VALIDATE_HOUR_VALID)
            .typeError(intl.VALIDATE_HOUR_VALID)
            .length(5, intl.VALIDATE_HOUR_VALID)
            .test('test-hour-valid', intl.VALIDATE_HOUR_VALID, function (hour) {
              return moment(hour, 'hh:mm').isValid();
            }),
          arrival_programmed_date: Yup.date()
            .validateDateIsSameOrAfterParentDate(
              intl.VALIDATE_DATE_VALID,
              'departure_programmed_date'
            )
            .required(intl.VALIDATE_DATE_VALID)
            .typeError(intl.VALIDATE_DATE_VALID),

          arrival_programmed_time: Yup.string()
            .required(intl.VALIDATE_HOUR_VALID)
            .typeError(intl.VALIDATE_HOUR_VALID)
            .length(5, intl.VALIDATE_HOUR_VALID)
            .test('test-hour-valid', intl.VALIDATE_HOUR_VALID, function (hour) {
              return moment(hour, 'hh:mm').isValid();
            })
            .test(
              'test-date-time-valid',
              intl.VALIDATE_HOUR_BIGGER_DEPARTURE,
              function (hour) {
                if (
                  moment(this.parent.departure_programmed_date).isValid() &&
                  moment(this.parent.arrival_programmed_date).isValid() &&
                  hour
                ) {
                  const splitArrivalHour = hour.split(':');
                  const splitDepartureHour = moment(
                    this.parent.departure_programmed_time,
                    'hh:mm'
                  ).isValid()
                    ? this.parent.departure_programmed_time.split(':')
                    : ['00', '00'];
                  const arrivalDate = moment(
                    this.parent.arrival_programmed_date
                  )
                    .add(
                      Number(splitArrivalHour[0] * 60) +
                        Number(splitArrivalHour[1]),
                      'minutes'
                    )
                    .format('YYYY-MM-DD HH:mm:ss');
                  const departureDate = moment(
                    this.parent.departure_programmed_date
                  )
                    .add(
                      Number(splitDepartureHour[0] * 60) +
                        Number(splitDepartureHour[1]),
                      'minutes'
                    )
                    .format('YYYY-MM-DD HH:mm:ss');
                  return moment(arrivalDate).isAfter(departureDate);
                }
                return true;
              }
            ),
        }),
      });
    }),

    total_amount_claimed: Yup.object().shape({
      value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    }),

    other_insurance_covering: Yup.object().shape({
      is_internal: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      value: Yup.string().when(
        ['is_internal'],
        {
          is: is_internal => {
            return is_internal === 'true';
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['is_internal']
      ),
    }),

    other_insurance_covering2: Yup.object().shape({
      is_internal: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      value: Yup.string().when(
        ['is_internal'],
        {
          is: is_internal => {
            return is_internal === 'true';
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['is_internal']
      ),
    }),
    additional_passengers: additionalShema(intl),
  });

const additionalShema = intl => {
  const additional = Yup.array().when(['hasAdditional'], {
    is: hasAdditional => {
      return hasAdditional === 'true';
    },
    then: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        birth_date: Yup.date()
          .max(new Date(), intl.VALIDATE_DATE_VALID)
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .required(intl.TEXT_FIELD_REQUIRED),
        gender: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        nationalities: Yup.string()
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .required(intl.TEXT_FIELD_REQUIRED),
        relationship: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        comment: Yup.string().when(['relationship'], {
          is: relationship => {
            return relationship === constants.OTHER_VALUE;
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .max(255, intl.TEXT_FIELD_VALIDATION_255),
        }),
      })
    ),
  });

  return additional;
};
