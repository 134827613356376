import { pt_nationalities } from '../../../../../../intl/pt_nationalities';
import { en_nationalities } from '../../../../../../intl/en_nationalities';
import { es_nationalities } from '../../../../../../intl/es_nationalities';

export const NATIONALITY_OPTIONS = {
  en_US: en_nationalities,
  pt_BR: pt_nationalities,
  es_ES: es_nationalities,
};

export const NATIONALITY_LABEL = {
  en_US: 'Nationality *',
  pt_BR: 'Nacionalidade *',
  es_ES: 'Nacionalidad *',
};

export const LABEL_CPF = {
  en_US: 'CPF',
  pt_BR: 'CPF',
  es_ES: 'CPF',
};

export const LABEL_CPF_TYPE = {
  en_US: 'CPF *',
  pt_BR: 'CPF *',
  es_ES: 'CPF *',
};

export const PLACEHOLDER_NATIONAL_ID = {
  en_US: 'National ID *',
  pt_BR: 'Identidade Nacional *',
  es_ES: 'Documento Nacional de Identidad *',
};

export const LABEL_IDENTIFICATION = {
  en_US: 'Identification',
  pt_BR: 'Identificação',
  es_ES: 'Identificación',
};
export const LABEL_CNPJ = {
  en_US: 'CNPJ *',
  pt_BR: 'CNPJ *',
  es_ES: 'CNPJ *',
};

export const LABEL_PASSPORT = {
  en_US: 'Passport',
  pt_BR: 'Passaporte',
  es_ES: 'Pasaporte',
};

export const LABEL_NATIONAL_ID = {
  en_US: 'National ID',
  pt_BR: 'Identidade Nacional',
  es_ES: 'Documento Nacional de Identidad',
};

export const PLACEHOLDER_PASSPORT = {
  en_US: 'Passport *',
  pt_BR: 'Passaporte *',
  es_ES: 'Pasaporte *',
};

export const NATIONALITY_DEPENDENT_LABEL = {
  en_US: 'Nationality of beneficiary *',
  pt_BR: 'Nacionalidade do beneficiário *',
  es_ES: 'Nacionalidad del beneficiario *',
};
