/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo, useState } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';
import { atmInitialValues } from './initialValues';
import { atmSchema } from './validationSchema';
import * as constants from './consts';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentPeriodArea from './IncidentPeriodArea/IncidentPeriodArea';
import IncidentLocation from './IncidentLocation/IncidentLocation';
import IncidentAmountArea from './IncidentAmountArea/IncidentAmountArea';
import IncidentInsuranceArea from './IncidentInsuranceArea/incidentInsuranceArea';
import { pt_BR } from '../../../../../../../intl/idioms';

const ATM = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef, loadedData } = props;

  const isBrazil = idiom === pt_BR;
  const validationSchema = useMemo(() => atmSchema(intl, isBrazil), [intl]);
  const initialValues = useMemo(
    () => atmInitialValues(parentValues.policy, idiom, loadedData),
    [idiom, parentValues.policy]
  );
  const [atmType, setAtmType] = useState(constants.VALUE_ASSAULT);
  const valueAssault = atmType === constants.VALUE_ASSAULT;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                role="btnSubmit"
              ></button>
              <PersonalArea
                parentValues={parentValues}
                {...props}
                setAtmType={setAtmType}
                atmType={atmType}
              />
              <IncidentPeriodArea parentValues={parentValues} {...props} />
              <IncidentLocation parentValues={parentValues} {...props} />
              {valueAssault && (
                <IncidentAmountArea parentValues={parentValues} {...props} />
              )}
              {valueAssault && (
                <IncidentInsuranceArea parentValues={parentValues} {...props} />
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ATM;
