import React, { useState, useEffect, useContext, useCallback } from 'react';
import styles from './RelatedSpecialBenefits.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import Button from '../../componentsV2/Button/Button';
import { StateContext } from '../StateContextParent/StateContextParent';
import { withRouter } from 'react-router-dom';
import BenefitItem from '../../pages/SpecialBenefits/ListBenefits/BenefitItem/BenefitItem';
import { productsAndBenefitsServices } from '../../services/index';
import { SPECIAL_BENEFIS } from '../../utils/benefitCategory';
import { IntlContext } from '../../intl';
import { PublicContext } from '../PublicContextParent/PublicContextParent';
import Carousel from '../Carousel/Carousel';
import Loading from '../Loading/Loading';
import * as translations from './intl';
import { BenefitsContext } from '../BenefitsContextParent/BenefitsContextParent';

const RelatedSpecialBenefits = props => {
  const { history, benefitSpecialList } = props;
  const [specialBenefits, setSpecialBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dragging, setDragging] = useState(false);
  const { idiomForApi, translate } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const intl = translate(translations);
  const { productActive } = useContext(PublicContext);
  const { hasSubscriptionBenefits } = useContext(BenefitsContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    beforeChange: () => handleBeforeChange(),
    afterChange: () => handleAfterChange(),
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
    ],
  };

  const redirectAllSpecialBenefits = () => {
    if (dragging) return;
    const url = utils.isUserLogged()
      ? `/your-subscription-benefits`
      : `/subscription-benefits`;

    history.push(url);
  };

  const productBinOrPan = utils.isUserLogged()
    ? utils.getBin(true)
    : productActive && productActive.product_id;

  const loadSpecialBenefits = async () => {
    setLoading(true);
    try {
      const benefits =
        benefitSpecialList && benefitSpecialList.length > 0
          ? benefitSpecialList
          : await productsAndBenefitsServices.getBenefitsByProduct(
              productBinOrPan,
              idiomForApi(),
              false,
              false
            );

      const specialbenefits =
        benefits && benefits.data
          ? benefits.data.filter(
              benefit => benefit.categories === SPECIAL_BENEFIS
            )
          : benefits.filter(benefit => benefit.categories === SPECIAL_BENEFIS);

      const requestDetailSpecialBenefits = specialbenefits.map(specialBenefit =>
        productsAndBenefitsServices.getBenefitsDetailByProduct(
          productBinOrPan,
          specialBenefit.benefit_id,
          idiomForApi(),
          false
        )
      );

      let detailSpecialBenefits = await Promise.all(
        requestDetailSpecialBenefits
      );
      detailSpecialBenefits = detailSpecialBenefits.map(
        especialBenefit => especialBenefit.data
      );
      setSpecialBenefits(detailSpecialBenefits);
    } catch (error) {
      setSpecialBenefits([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (productBinOrPan) {
      loadSpecialBenefits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productActive, productBinOrPan]);

  if (loading) {
    return (
      <div className={styles.center}>
        <Loading />
      </div>
    );
  }

  if (specialBenefits.length === 0 || !hasSubscriptionBenefits) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.header} ${styles[theme]}`}>
        <h2 className={`${styles.title} ${styles[theme]}`}>
          {intl.TEXT_ESPECIAL_BENEFITS}
        </h2>
        <Button
          type="Secondary"
          onClick={redirectAllSpecialBenefits}
          id="subscriptionBenefits-viewAll-mobile"
          label={intl.VIEW_ALL}
        />
      </div>

      <div className={styles.boxCarousel}>
        <Carousel settings={settings}>
          {specialBenefits &&
            specialBenefits.map(specialBenefit => (
              <BenefitItem
                itemCarousel
                specialBenefit={specialBenefit}
                key={specialBenefit.benefit_id}
              />
            ))}
        </Carousel>
      </div>

      <div className={styles.contentButtonMobile}>
        <Button
          type="Secondary"
          onClick={redirectAllSpecialBenefits}
          id="subscriptionBenefits-viewAll-mobile"
          label={intl.VIEW_ALL}
        />
      </div>
    </div>
  );
};

export default withRouter(RelatedSpecialBenefits);
