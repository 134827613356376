import React, { useContext, useMemo } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import CheckBox from '../../../../../../components/CheckBox/CheckBox';
import {
  legalAdviceFormInitialValues,
  validationSchema,
} from './validationsAndValues';
import styles from './LegalAdviceAndConsent.module.scss';
import InformationArea from './InformationArea/InformationArea';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';
import {
  price_protection,
  emergency_medical_assistance,
} from '../../../../../../utils/claims_name';

const LegalAdviceAndConsent = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    updateParent,
    parentValues,
    legalSubmitRef,
    loadedData = [],
    claimType,
  } = props;
  const initialValues = useMemo(
    () => legalAdviceFormInitialValues(loadedData),
    [loadedData]
  );
  const schema = useMemo(() => validationSchema(intl), [intl]);
  const type_2 = ['Cellphone_Protection', 'Bag_Protection'];
  const checkbox_accord = type_2.includes(claimType)
    ? intl.CHECKBOX_ACCORD_2
    : intl.CHECKBOX_ACCORD;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const isPriceProtection = claimType === price_protection;
  const isBrazil = idiom === 'pt_BR';

  const isIEMS = claimType === emergency_medical_assistance;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
        } = props;
        updateParent(values, schema);

        const setConfirm = async (index, value) => {
          await setFieldValue(index + '.is_confirmed', value);
          const date = moment().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
          await setFieldValue(index + '.consented_at', date);
          await setFieldTouched(index + '.is_confirmed');
        };

        return (
          <form
            onSubmit={handleSubmit}
            data-testid="FullFormCliamGeneralInformation"
          >
            <button
              ref={legalSubmitRef}
              type="submit"
              style={{ display: 'none' }}
            ></button>
            <div className={styles.legalAdviceAndConsent}>
              <div className={styles.content}>
                {isPriceProtection && isBrazil && (
                  <p className={styles[themes]}>
                    {intl.PRICE_PROTECTION_DISCLAIMER_BR}
                  </p>
                )}
                {isIEMS && !isBrazil && (
                  <p className={styles[themes]}>{intl.TEXT_INFO_R_IEMS}</p>
                )}
                <h4 className={styles[themes]}>
                  {isIEMS && !isBrazil
                    ? intl.TEXT_INFO_TWO_R_IEMS
                    : intl.TEXT_INFO}
                </h4>
                <p className={styles[themes]}>
                  {isIEMS && !isBrazil
                    ? intl.TEXT_ADVISE_R_IEMS
                    : intl.TEXT_ADVISE}
                </p>
                {isIEMS && !isBrazil && (
                  <p className={styles[themes]}>
                    {intl.TEXT_ADVISE_TWO_R_IEMS}
                  </p>
                )}
                <h5 className={styles[themes]}>
                  {isIEMS && !isBrazil
                    ? intl.TEXT_INFO_TRUE_R_IEMS
                    : intl.TEXT_INFO_TRUE}
                </h5>
              </div>
              <div className={`${styles.checkbox} ${styles[themes]}`}>
                <div className={styles.itemCheckBox}>
                  <CheckBox
                    type="checkbox"
                    value={values.is_confirm.is_confirmed}
                    name={'is_confirm.is_confirmed'}
                    touched={
                      touched.is_confirm && touched.is_confirm.is_confirmed
                    }
                    error={errors.is_confirm && errors.is_confirm.is_confirmed}
                    dataTestid="CheckboxAcceptTermsSubmit"
                    onClick={value => setConfirm('is_confirm', value)}
                  >
                    {isIEMS
                      ? intl.CHECKBOX_UNDERSTAND_R_IEMS
                      : intl.CHECKBOX_UNDERSTAND}
                  </CheckBox>
                </div>
                <div className={styles.itemCheckBox}>
                  <CheckBox
                    type="checkbox"
                    value={values.is_authorized.is_confirmed}
                    name={'is_authorized.is_confirmed'}
                    touched={
                      touched.is_authorized &&
                      touched.is_authorized.is_confirmed
                    }
                    error={
                      errors.is_authorized && errors.is_authorized.is_confirmed
                    }
                    dataTestid="CheckboxAuthorizeTermsSubmit"
                    onClick={value => setConfirm('is_authorized', value)}
                  >
                    {isIEMS && !isBrazil
                      ? intl.CHECKBOX_AUTHORIZE_R_IEMS
                      : intl.CHECKBOX_AUTHORIZE}
                  </CheckBox>
                </div>
                <div className={styles.itemCheckBox}>
                  <CheckBox
                    type="checkbox"
                    value={values.is_accord.is_confirmed}
                    name={'is_accord.is_confirmed'}
                    touched={
                      touched.is_accord && touched.is_accord.is_confirmed
                    }
                    error={errors.is_accord && errors.is_accord.is_confirmed}
                    dataTestid="CheckboxAccordTermsSubmit"
                    onClick={value => setConfirm('is_accord', value)}
                  >
                    {isIEMS ? intl.CHECKBOX_ACCORD_R_IEMS : checkbox_accord}
                  </CheckBox>
                </div>
              </div>
              <InformationArea parentValues={parentValues} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default LegalAdviceAndConsent;
