/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import Select from '../../../../../../components/Select/Select';
import Input from '../../../../../../components/Input/Input';
import sanitize from '../../../../../../utils/sanitize';
import {
  mask4FirstDigits,
  removeRequiredCharLabel,
} from '../../../../../../utils/stringUtils';
import styles from './RegistrationsArea.module.scss';
import { pt_BR } from '../../../../../../intl/idioms';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import { removeNonNumeric } from '../../../../../../utils/stringUtils';

const RegistrationsArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    initialValues,
    setFieldTouched,
  } = props;
  const selectedCard = utils.getSelectedCard();
  const isCTA = selectedCard.name.includes('CTA');
  const isBrasil = idiom === pt_BR;
  const isBrasilAndNotCTA = isBrasil && !isCTA;
  const [indexRolac, setIndexRolac] = useState(isBrasil ? 1 : 0);

  const conditionBRShowFields = isBrasil ? true : values.nationalities === 'BR';

  const foreignersWithBrazilianCard = isBrasil && values.nationalities !== 'BR';
  const [registrationType, setRegistrationType] = useState(
    values.person_registrations[0].registration_type === 'ID_CARD'
      ? intl.PLACEHOLDER_NATIONAL_ID
      : intl.PLACEHOLDER_PASSPORT
  );

  const checkSameRegistrationValue = index => {
    if (
      values.person_registrations[index].registration_type ===
        initialValues.person_registrations[index].registration_type &&
      initialValues.person_registrations[index].value !== ''
    ) {
      return (
        values.person_registrations[index].value ===
        initialValues.person_registrations[index].value
      );
    } else {
      return false;
    }
  };

  const getInitialRegistrationValue = index => {
    if (
      values.person_registrations[index].registration_type ===
      initialValues.person_registrations[index].registration_type
    ) {
      return initialValues.person_registrations[index].value;
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (!isBrasil) {
      if (values.nationalities === 'BR') {
        setFieldValue('person_registrations[0].registration_type', 'CPF');
      }
      if (
        values.nationalities !== 'BR' &&
        values.nationalities !== '' &&
        values.person_registrations[0].registration_type === 'CPF' &&
        !isBrasil
      ) {
        setFieldValue('person_registrations[0].registration_type', 'PASSPORT');
      }
    }
  }, [values.nationalities, values.person_registrations, setFieldValue]);

  useEffect(() => {
    if (
      values.person_registrations[0].registration_type ===
      initialValues.person_registrations[0].registration_type
    ) {
      setFieldValue(
        `person_registrations[${0}].value`,
        getInitialRegistrationValue(0)
      );
    } else {
      setFieldValue(`person_registrations[${0}].value`, '');
    }
  }, [values.person_registrations[0].registration_type]);

  useEffect(() => {
    if (isBrasil) {
      if (
        values.person_registrations[indexRolac].registration_type ===
        initialValues.person_registrations[indexRolac].registration_type
      ) {
        setFieldValue(
          `person_registrations[${indexRolac}].value`,
          getInitialRegistrationValue(indexRolac)
        );
      } else {
        setFieldValue(`person_registrations[${indexRolac}].value`, '');
      }
    }
  }, [values.person_registrations[indexRolac].registration_type]);

  useEffect(() => {
    if (isBrasil && values.person_registrations.length === 1) {
      values.person_registrations[0] = { registration_type: 'CPF', value: '' };
      values.person_registrations.push({
        registration_type: '',
        value: '',
      });
      setIndexRolac(1);
    }
  }, []);

  useEffect(() => {
    setRegistrationType(
      values.person_registrations[indexRolac].registration_type === 'ID_CARD'
        ? intl.PLACEHOLDER_NATIONAL_ID
        : intl.PLACEHOLDER_PASSPORT
    );
  }, [values.person_registrations]);
  const idOptionROLAC = (
    <div className="col-12 col-sm-12 col-md-6" style={{ padding: 0 }}>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-12 col-sm-12 col-md-6">
          <Select
            label={
              isBrasilAndNotCTA
                ? intl.LABEL_IDENTIFICATION
                : intl.LABEL_IDENTIFICATION + ' *'
            }
            value={values.person_registrations[indexRolac].registration_type}
            name={`person_registrations[${indexRolac}].registration_type`}
            onChange={handleChange}
            touched={
              touched.person_registrations &&
              touched.person_registrations[indexRolac] &&
              touched.person_registrations[indexRolac].registration_type
            }
            onBlur={handleBlur}
            error={
              errors.person_registrations &&
              errors.person_registrations[indexRolac] &&
              errors.person_registrations[indexRolac].registration_type
            }
            shouldBeSmaller={intl.PLACEHOLDER_NATIONAL_ID === registrationType}
          >
            <option value="">
              {isBrasilAndNotCTA
                ? intl.LABEL_IDENTIFICATION
                : intl.LABEL_IDENTIFICATION + ' *'}
            </option>
            <option value="PASSPORT">{intl.LABEL_PASSPORT}</option>
            <option value="ID_CARD">{intl.LABEL_NATIONAL_ID}</option>
          </Select>
        </div>

        {values.person_registrations[indexRolac] &&
          values.person_registrations[indexRolac].registration_type &&
          values.person_registrations[indexRolac].registration_type != '' && (
            <div className="col-12 col-sm-12 col-md-6">
              <Input
                value={
                  checkSameRegistrationValue(indexRolac)
                    ? mask4FirstDigits(
                        values.person_registrations[indexRolac].value
                      )
                    : values.person_registrations[indexRolac].value
                }
                label={
                  isBrasilAndNotCTA
                    ? removeRequiredCharLabel(registrationType)
                    : registrationType
                }
                name={`person_registrations[${indexRolac}].value`}
                dataTestid="InputCHRegistrationValue-passport"
                type="text"
                onBlur={e => {
                  handleBlur(e);
                  if (e.target.value === '') {
                    setFieldValue(
                      `person_registrations[${indexRolac}].value`,
                      getInitialRegistrationValue(indexRolac)
                    );
                    setFieldTouched(
                      `person_registrations[${indexRolac}].value`,
                      true
                    );
                  }
                }}
                onFocus={e => {
                  if (checkSameRegistrationValue(indexRolac)) {
                    setFieldValue(
                      `person_registrations[${indexRolac}].value`,
                      ''
                    );
                    setFieldTouched(
                      `person_registrations[${indexRolac}].value`,
                      true
                    );
                  }
                }}
                mask={
                  checkSameRegistrationValue(indexRolac)
                    ? 'maskedPassaportWithoutFirst4'
                    : 'maskedPassaport'
                }
                onChange={handleChange}
                touched={
                  touched.person_registrations &&
                  touched.person_registrations[indexRolac] &&
                  touched.person_registrations[indexRolac].value
                }
                error={
                  errors.person_registrations &&
                  errors.person_registrations[indexRolac] &&
                  errors.person_registrations[indexRolac].value
                }
              />
            </div>
          )}
      </div>
    </div>
  );

  return (
    <div className={`row ${styles.section}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Select
          label={intl.NATIONALITY_DEPENDENT_LABEL}
          value={values.nationalities}
          error={errors && errors.nationalities}
          name={`nationalities`}
          touched={touched && touched.nationalities}
          onBlur={handleBlur}
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(intl.NATIONALITY_OPTIONS),
          }}
          dataTestid="nationalities"
        />
      </div>
      {conditionBRShowFields ? (
        <>
          <div className="col-12 col-sm-12 col-md-6">
            <Input
              value={
                checkSameRegistrationValue(0)
                  ? mask4FirstDigits(
                      removeNonNumeric(values.person_registrations[0].value)
                    )
                  : values.person_registrations[0].value
              }
              label={isCTA ? intl.LABEL_CPF : intl.LABEL_CPF_TYPE}
              name={`person_registrations[0].value`}
              type="text"
              mask={
                checkSameRegistrationValue(0)
                  ? 'maskedCPFWithoutFirst4'
                  : 'maskedCPF'
              }
              onChange={handleChange}
              touched={
                touched.person_registrations &&
                touched.person_registrations[0] &&
                touched.person_registrations[0].value
              }
              onBlur={e => {
                handleBlur(e);
                if (e.target.value === '') {
                  setFieldValue(
                    `person_registrations[0].value`,
                    getInitialRegistrationValue(0)
                  );
                  setFieldTouched(`person_registrations[0].value`, true);
                }
              }}
              onFocus={e => {
                if (checkSameRegistrationValue(0)) {
                  setFieldValue(`person_registrations[0].value`, '');
                  setFieldTouched(`person_registrations[0].value`, true);
                }
              }}
              error={
                errors.person_registrations &&
                errors.person_registrations[0] &&
                errors.person_registrations[0].value
              }
              dataTestid="personRegistrations"
            />
          </div>
          {isBrasil && <>{idOptionROLAC}</>}
        </>
      ) : (
        <>{idOptionROLAC}</>
      )}
    </div>
  );
};

export default RegistrationsArea;
