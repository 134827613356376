/* eslint-disable no-unused-vars */
const agencyModule = sumSeq => {
  var result = 11 - (sumSeq % 11);
  if (result === 10) {
    return 'P';
  } else {
    if (result === 11) {
      return '0';
    } else {
      return result.toString();
    }
  }
};

const accountModule = sumSeq => {
  var module = sumSeq % 11;
  if (module === 0) {
    return '0';
  } else {
    if (module === 1) {
      return 'P';
    } else {
      return (11 - module).toString();
    }
  }
};

const multiplyAccordingWeight = (number, i) => {
  var weight = [2, 7, 6, 5, 4, 3, 2];
  return number * weight[i];
};

const agencyNumberIsValid = agencyNumber => {
  return /^[0-9]{1,5}$/.test(agencyNumber) && parseInt(agencyNumber) > 0;
};

const accountNumberLength = () => {
  return 9;
};

const agencyNumberLength = () => {
  return 4;
};

/**
 * Receive account and agency with '-'
 * Agency format: xxxx-x
 * Account format: xxxxxxx-x
 */
export default () => ({
  account: (code, agency = null) => {
    if (!code || code.length !== accountNumberLength()) return false;
    if (code.length !== accountNumberLength()) return false;
    let arrayCode = code.split('-');
    let numbers = arrayCode[0].split('');
    let dv = arrayCode[1];
    let sumSeq = 0;

    for (var i = 0; i < numbers.length; i++) {
      let number = parseInt(numbers[i]);
      sumSeq += multiplyAccordingWeight(number, i);
    }

    const calculatedNumber = accountModule(sumSeq);

    if (dv === '0' && calculatedNumber === 'P') {
      return true;
    }
    return (
      calculatedNumber.toString().toUpperCase() === dv.toString().toUpperCase()
    );
  },
  agency: code => {
    if (!code || code.length !== agencyNumberLength()) return false;
    return agencyNumberIsValid(code);
  },
  /*agency: (code) => {
    if (code.length !== agencyNumberLength()) return false;
    let arrayCode = code.split("-");
    let numbers = arrayCode[0].split("");
    let dv = arrayCode[1];

    let sumSeq = 0;
    for (var i = 0; i < numbers.length; i++) {
      let seq = 5 - i;
      sumSeq += parseInt(numbers[i]) * seq;
    }

    const calculatedNumber = agencyModule(sumSeq);

    return (
      calculatedNumber.toString().toUpperCase() === dv.toString().toUpperCase()
    );
  },
   */
  accountNumberLength: code => {
    if (!code) return false;
    return code.length === accountNumberLength();
  },
  agencyNumberLength: code => {
    if (!code) return false;
    return code.length === agencyNumberLength();
  },
  getAgencyNumberLength: () => {
    return agencyNumberLength();
  },
  getAccountNumberLength: () => {
    return accountNumberLength();
  },
  getMaskAgencyName: () => {
    return 'maskBradescoBranchCode';
  },
  getMaskAccountName: () => {
    return 'maskBradescoAccountNumber';
  },
  isGenericValidation: () => {
    return false;
  },
  getFormatAccount: () => {
    return '0000000-0';
  },
});
