export const CEP_ERROR =
  'Invalid request body. beneficiaries.address.postal_code';
export const CEP_NOT_FOUND = 'CEP nao encontrado.';
export const ERROR_DUPLICATED_CARDHOLDER =
  'Error: Bilhete já emitido anteriormente (duplicidade)';
export const ERROR_DUPLICATED_CARDHOLDER_WITHOU_ACCENT =
  'Error: Bilhete ja emitido anteriormente (duplicidade)';
export const ERROR_DUPLICATED_DEPENDENTS =
  'The dependent ticket was not validated by AIG. Error: Bilhete ja emitido anteriormente (duplicidade)';
export const CEP_NOT_FOUND_MESSAGE_AIG =
  'The ticket was not validated by AIG. Error: CEP nao encontrado.';
export const ERROR_AIG_NOT_AVAILABLE = 'AIG Service not available';
export const CEP_NOT_FOUND_MESSAGE_AIG_DEPENDENT =
  'The dependent ticket was not validated by AIG. Error: CEP nao encontrado.';

export const ERROR_CPF_DEPENDENT_CERTIFICATE =
  'The dependent ticket was not validated by AIG. Error: Campo Mandatório (ds_cpf) não preenchido';

export const ERROR_CUSTOMER_IS_NOT_COVERED_CERTIFICATE_TYPE =
  'The customer is not covered for this certificate type.';

export const ERROR_BIN_NUMBER_FROM_BRASIL_MUST_USE_CPF =
  'Invalid request body. BIN number from Brazil issuer must use CPF registration type';

export const ERROR_CPF_CAN_BE_USED_ONLY_BY_BRAZILIAN_BIN =
  'Invalid request body. CPF registration type can only be used for BIN numbers related to Brazil issuers';

export const ERROR_BRAZILIAN_DEPENDENT_MUST_USE_CPF =
  'Invalid request body. Brazil dependent issuer must use CPF registration type';

export const ERROR_DUPLICATED_CARDHOLDER_CERTIFICATE_HAS_ALREADY_BEEN_ISSUED =
  'Certificate has already been issued';
export const ERROR_DUPLICATED_DEPENDENT_CERTIFICATE_HAS_ALREADY_BEEN_ISSUED =
  'Dependent certificate has already been issued';

export const ERROR_CPF_MUST_BE_UNIQUE_BETWEEN_CARDHOLDER_AND_DEPENDENT =
  'The CPF is a unique identifier and cannot be shared between the cardholder and their dependents.';
